import {ElementRef, Injector} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {
  AbpMultiTenancyService,
  FeatureCheckerService,
  LocalizationService,
  MessageService,
  NotifyService,
  PermissionCheckerService,
  SettingService
} from 'abp-ng2-module';

import {AppSessionService} from '@shared/session/app-session.service';
import {MenuStateService} from '@shared/services/menuState.service';

export abstract class AppComponentBase {
  minDate = new Date();

  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: NotifyService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  elementRef: ElementRef;
  menuStateService: MenuStateService;

  constructor(
    injector: Injector,

  ) {
    this.localization = injector.get(LocalizationService);
    this.permission = injector.get(PermissionCheckerService);
    this.feature = injector.get(FeatureCheckerService);
    this.notify = injector.get(NotifyService);
    this.setting = injector.get(SettingService);
    this.message = injector.get(MessageService);
    this.multiTenancy = injector.get(AbpMultiTenancyService);
    this.appSession = injector.get(AppSessionService);
    this.elementRef = injector.get(ElementRef);
    this.menuStateService = injector.get(MenuStateService);
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(key, this.localizationSourceName);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }

  enableMenuLinks() {
    this.menuStateService.enableMenuLinks();
  }

  disableMenuLinks() {
    this.menuStateService.disableMenuLinks();
  }
}
