import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class MenuStateService {
  // EventEmitter для активации или деактивации всех ссылок
  public linkStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Функция для активации всех ссылок
  enableMenuLinks() {
    this.linkStateChanged.emit(true);
  }

  // Функция для деактивации всех ссылок
  disableMenuLinks() {
    this.linkStateChanged.emit(false);
  }

}
