import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: any, format = 'YYYY-MM-DD'): string | null {
    if (value) {
      return moment(value).format(format);
    }
    return null;
  }
}
